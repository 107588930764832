<template>
  <div class="ifno_TITLE">
    <titleNav title="e证e卡" />
    <div class="imgUrl">
      <img src="@/assets/images/qrcode_for_gh_49aab3981383_258 (2).jpg" alt="" />
    </div>
    <p>
      请长按识别二维码，关注“e证e卡”微信公众号后，再进行相关业务办理。
    </p>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
export default {
  components: {
    titleNav,
  },
  data() {
    return {};
  },
  created() {
    // this.guanbi();
  },
  mounted() {
    
  },
  methods: {
    guanbi() {
      let url = window.location.href;
      console.log(url, "路由地址");
      if (url == "http://lhjy.sjzydrj.net/") {
        setTimeout(function () {
          //这个可以关闭安卓系统的手机
          document.addEventListener(
            "WeixinJSBridgeReady",
            function () {
              WeixinJSBridge.call("closeWindow");
            },
            false
          );
          //这个可以关闭ios系统的手机
          WeixinJSBridge.call("closeWindow");
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ifno_TITLE {
  .imgUrl {
    text-align: center;
    img {
      width: 80%;
    }
  }

  p {
    font-size: 18px;
    margin: 0 20px;
    text-indent: 2em;
  }
}
</style>
